import request from '@/utils/request'

// ------------------------用户信息管理-------------------------

// 获取测评报告管理
export function getEvaluateReport() {
    return request({
        url: '/index_measure',
        method: 'get',
    })
}

// 获取档案详情
export function getArchives(data) {
    return request({
        url: '/get_archives',
        method: 'get',
        params: data,
    })
}

// 获取档案列表
export function listArchives(data) {
    return request({
        url: '/list_archives',
        method: 'get',
        params: data,
    })
}

// 创建档案
export function createdArchives(data) {
    return request({
        url: '/created_archives',
        method: 'post',
        data,
    })
}
// 修改档案
export function updateArchives(data) {
    return request({
        url: '/update_archives',
        method: 'post',
        data,
    })
}

// 删除档案
export function deleteArchives(data) {
    return request({
        url: '/delete_archives',
        method: 'get',
        params: data,
    })
}



// --------------------测评报告管理页面---------------------


// 获取报告列表
export function indexMeasure(data) {
    return request({
        url: '/index_measure',
        method: 'get',
        params: data,
    })
}

//获取晤谈报告管理
export function listInterviewReport(data) {
    return request({
        url: '/list_interview_report',
        method: 'get',
        params: data,
    })
}

// 获取晤谈报告详情
export function getInterviewReport(data) {
    return request({
        url: '/get_interview_report',
        method: 'get',
        params: data,
    })
}

// 审核是Api
export function inspectUp(data) {
    return request({
        url: '/inspect_up',
        method: 'get',
        params: data,
    })
}

// 删除测评报告
export function delEvaluate(data) {
    return request({
        url: '/del_evaluate',
        method: 'get',
        params: data,
    })
}
// 报告切换部门
export function department_up(data) {
    return request({
        url: '/department_up',
        method: 'get',
        params: data,
    })
}



// -----------------录入测评数据--------------------------

export function adminResultsCalculated(data) {
    let urlapi = '';
    if (data.hasOwnProperty('account')) {
        urlapi = '/insert_user_evaluate_log'; // 添加用户
    } else {
        urlapi = '/admin_results_calculated'; // 查询用户
    }
    return request({
        url: urlapi,
        method: 'post',
        data,
    })
}



// ------------------导出 Excel  测评结果------------------
export function getExcel(data) {
    return request({
        url: '/get_excel',
        method: 'get',
        params: data,
        // responseType: "blob"
    })
}


export function postExcel(data) {
    return request({
        url: '/query_token',
        method: 'post',
        data,
        // responseType: "blob"
    })
}

// =======================================
//删除唔谈 
export function delete_interview_log(data) {
    return request({
        url: '/delete_interview_log',
        method: 'get',
        params: data,
    })
}